<template>
  <div class="bg-white w-full py-5">
    <div class="flex gap-4 items-center justify-between max-w-5xl lg:max-w-7xl 2xl:max-w-8xl mx-auto px-4">
      <router-link to="/">
        <img class="mx-auto" src="@/assets/logos/Homeplan logo (new modern April 2022 - NO .com).svg" style="height: 2rem;" alt="Homeplan logo">
      </router-link>

      <button @click="open = !open" class="block md:hidden">
        <img v-if="!open" class="h-8 w-8" src="https://img.icons8.com/ios-filled/50/1e3a8a/menu--v1.png"/>
        <img v-else class="h-8 w-8" src="https://img.icons8.com/ios-filled/50/1e3a8a/delete-sign--v1.png"/>
      </button>

      <div class="hidden md:flex gap-4 md:gap-6 items-center">
        <router-link class="text-blue-900 font-medium" to="/our-story">Our Story</router-link>
        <router-link class="text-blue-900 font-medium" to="/case-studies">Case Studies</router-link>

        <router-link class="text-blue-900 font-medium" to="/blog">Blog</router-link>
        <router-link class="text-blue-900 font-medium" to="/pricing">Pricing</router-link>
        <button v-if="$route.path == '/'" @click="scrollTo('faq')" class="text-blue-900 font-medium" to="#FAQ">FAQ</button>
        <router-link v-else class="text-blue-900 font-medium" to="/#faq">FAQ</router-link>
        <router-link class="lg:block hidden text-blue-900 font-medium" to="/jobs">Careers - We're Hiring</router-link>
        <router-link class="block lg:hidden text-blue-900 font-medium" to="/jobs">Careers</router-link>
      </div>

      <div class="hidden md:flex gap-4 md:gap-6 items-center">
        <a class="pl-6 pr-1 text-blue-900 font-medium" href="https://www.beta.joinhomeplan.com/login/">Log in</a>
        <button @click="getEarlyAccess" class="py-3 px-3 flex items-center gap-4 bg-blue-900 hover:bg-blue-900 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
          Get early access 
          <img class="h-6" src="https://img.icons8.com/material-rounded/48/ffffff/right.png"/>
        </button>
      </div>
    </div>

    <div :class="{ 'hidden': !open }" class="block md:hidden bg-white shadow-xl absolute w-full px-8 py-8">
      <div class="flex flex-col gap-4 md:gap-8 items-center">
        <router-link class="text-blue-900 font-medium" to="/our-story">Our Story</router-link>
        <router-link class="text-blue-900 font-medium" to="/case-studies">Case Studies</router-link>

        <router-link class="text-blue-900 font-medium" to="/blog">Blog</router-link>
        <router-link class="text-blue-900 font-medium" to="/pricing">Pricing</router-link>
        
        <button v-if="$route.path == '/'" @click="scrollTo('faq')" class="text-blue-900 font-medium" to="#FAQ">FAQ</button>
        <router-link v-else class="text-blue-900 font-medium" to="/#faq">FAQ</router-link>
        <router-link class="text-blue-900 font-medium" to="/jobs">Careers - We're Hiring</router-link>
        
        <a class="pt-6 text-blue-900 font-medium" href="https://www.beta.joinhomeplan.com/login/">Log in</a>
        <div>
          <button @click="getEarlyAccess" class="w-full py-3 px-4 flex items-center gap-4 bg-blue-900 hover:bg-blue-900 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
            Get early access 
            <img class="h-6" src="https://img.icons8.com/material-rounded/48/ffffff/right.png"/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['email'],
  data() {
    return {
      open: false
    }
  },
  methods: {
    scrollTo(id) {
      let e = document.getElementById(id);
      this.open = false;

      e.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
        inline: 'start'
      });
    },

    getEarlyAccess() {
      if (this.email) {
        window.location = (`https://www.beta.joinhomeplan.com/?email=${this.email}`);
      } else {
        window.location = (`https://www.beta.joinhomeplan.com/`);
      }
    },
  }
}
</script>