<template>
  <div class="bg-hp-gray" style="min-height: 100vh;">
    <header class="w-full">
      <Menu class="bg-hp-gray" />
    </header>


    <!-- Main content -->
    <div class="pt-36 pb-80 max-w-5xl lg:max-w-6xl 2xl:max-w-8xl mx-auto px-4" style="min-height: 100vh;">
      <div class="text-center text-blue-900 font-bold text-4xl mb-16">
        Careers
      </div>


      <div class="grid grid-cols-2 gap-6">
        <div class="px-6 py-7 flex flex-col justify-between cursor-pointer rounded-lg shadow-lg transition duration-200 ease-in-out overflow-hidden bg-white">
          <div>
            <div class="font-semibold text-2xl text-blue-900">Full Stack Engineer</div>
            <div class="text-lg mb-8">We are looking for a full stack engineer who has a passion for building projects that help everyday people.</div>

            <div class="font-medium text-lg">Skills</div>
            <ul class="mb-12">
              <li class="text-lg">Javascript frameworks (Vue JS, React, etc.)</li>
              <li class="text-lg">Node JS APIs</li>
              <li class="text-lg">AWS (DynamoDB, Cognito, S3, Amplify)</li>
            </ul>
          </div>

          <div>
            <button @click="getInTouchPressed('alex@joinhomeplan.com')" class="flex gap-2 items-center py-3 px-4 text-center bg-blue-900 hover:bg-blue-900 text-white transition ease-in duration-200 text-base font-semibold shadow-md focus:outline-none rounded-lg">
              Get in touch
              <img class="h-6" src="https://img.icons8.com/material-rounded/48/ffffff/right.png"/>
            </button>
          </div>
        </div>


        <div class="px-6 py-7 flex flex-col justify-between cursor-pointer rounded-lg shadow-lg transition duration-200 ease-in-out overflow-hidden bg-white">
          <div>
            <div class="font-semibold text-2xl text-blue-900">Backend Engineer</div>
            <div class="text-lg mb-8">We are looking for a backend engineer who has a passion for architecture.</div>

            <div class="font-medium text-lg">Skills</div>
            <ul class="mb-12">
              <li class="text-lg">Node JS</li>
              <li class="text-lg">GraphQL APIs</li>
              <li class="text-lg">AWS (DynamoDB, Cognito, S3, Amplify)</li>

            </ul>
          </div>

          <div>
            <button @click="getInTouchPressed('alex@joinhomeplan.com')" class="flex gap-2 items-center py-3 px-4 text-center bg-blue-900 hover:bg-blue-900 text-white transition ease-in duration-200 text-base font-semibold shadow-md focus:outline-none rounded-lg">
              Get in touch
              <img class="h-6" src="https://img.icons8.com/material-rounded/48/ffffff/right.png"/>
            </button>
          </div>
        </div>


        <div class="px-6 py-7 flex flex-col justify-between cursor-pointer rounded-lg shadow-lg transition duration-200 ease-in-out overflow-hidden bg-white">
          <div>
            <div class="font-semibold text-2xl text-blue-900 mb-8">Claims Specialist</div>

            <div class="font-medium text-lg">Skills</div>
            <ul class="mb-12">
              <li class="text-lg">Experience filing Supplements with Insurance companies (Roof, Home Warranty)</li>
              <li class="text-lg">Strong knowledge of terminology</li>
              <li class="text-lg">Outcome and mission driven</li>
              <li class="text-lg">Current or previous home owner (not required but a plus)</li>
            </ul>
          </div>

          <div>
            <button @click="getInTouchPressed('bash@joinhomeplan.com')" class="flex gap-2 items-center py-3 px-4 text-center bg-blue-900 hover:bg-blue-900 text-white transition ease-in duration-200 text-base font-semibold shadow-md focus:outline-none rounded-lg">
              Get in touch
              <img class="h-6" src="https://img.icons8.com/material-rounded/48/ffffff/right.png"/>
            </button>
          </div>
        </div>


        <div class="px-6 py-7 flex flex-col justify-between cursor-pointer rounded-lg shadow-lg transition duration-200 ease-in-out overflow-hidden bg-white">
          <div>
            <div class="font-semibold text-2xl text-blue-900 mb-8">Content Writer</div>

            <div class="font-medium text-lg">Skills</div>
            <ul class="mb-12">
              <li class="text-lg">Prior experience writing real estate content/blogs</li>
              <li class="text-lg">Strong writing skills and ability to research and filter data</li>
              <li class="text-lg">Lover of all things Home related</li>
            </ul>
          </div>

          <div>
            <button @click="getInTouchPressed('bash@joinhomeplan.com')" class="flex gap-2 items-center py-3 px-4 text-center bg-blue-900 hover:bg-blue-900 text-white transition ease-in duration-200 text-base font-semibold shadow-md focus:outline-none rounded-lg">
              Get in touch
              <img class="h-6" src="https://img.icons8.com/material-rounded/48/ffffff/right.png"/>
            </button>
          </div>
        </div>


        <div class="px-6 py-7 flex flex-col justify-between cursor-pointer rounded-lg shadow-lg transition duration-200 ease-in-out overflow-hidden bg-white">
          <div>
            <div class="font-semibold text-2xl text-blue-900">Home Manager</div>
            <div class="text-lg mb-8">We are looking for a current homeowner to become a home manager.</div>

            <div class="font-medium text-lg">Skills</div>
            <ul class="mb-12">
              <li class="text-lg">Experience in Customer Success/Service</li>
              <li class="text-lg">Strong knowledge of homeowners pain points with homeownership</li>
              <li class="text-lg">Ability to empathize</li>
              <li class="text-lg">Strong desire to solve problems for everyday people</li>
              <li class="text-lg">Outcome and mission driven</li>
            </ul>
          </div>

          <div>
            <button @click="getInTouchPressed('bash@joinhomeplan.com')" class="flex gap-2 items-center py-3 px-4 text-center bg-blue-900 hover:bg-blue-900 text-white transition ease-in duration-200 text-base font-semibold shadow-md focus:outline-none rounded-lg">
              Get in touch
              <img class="h-6" src="https://img.icons8.com/material-rounded/48/ffffff/right.png"/>
            </button>
          </div>
        </div>

        <div class="px-6 py-7 flex flex-col justify-between cursor-pointer rounded-lg shadow-lg transition duration-200 ease-in-out overflow-hidden bg-white">
          <div>
            <div class="font-semibold text-2xl text-blue-900">Home Inspector</div>
            <div class="text-lg mb-8">Have a passion for helping homeowners.</div>

            <div class="font-medium text-lg">Skills</div>
            <ul class="mb-12">
              <li class="text-lg">ASHI or InterNACHI certification</li>
              <li class="text-lg">250 fee paid inspections</li>
              <li class="text-lg">Ability/experience generating high quality reports</li>
              <li class="text-lg">Ability to work within a team</li>
            </ul>
          </div>

          <div>
            <button @click="getInTouchPressed('bash@joinhomeplan.com')" class="flex gap-2 items-center py-3 px-4 text-center bg-blue-900 hover:bg-blue-900 text-white transition ease-in duration-200 text-base font-semibold shadow-md focus:outline-none rounded-lg">
              Get in touch
              <img class="h-6" src="https://img.icons8.com/material-rounded/48/ffffff/right.png"/>
            </button>
          </div>
        </div>
      </div>
    </div>


    
    <!-- Footer -->
    <Footer />
  </div>
</template>

<script>
import Menu from "@/components/menu";
import Footer from "@/components/large-footer.vue";

export default {
  components: {
    Menu,
    Footer
  },
  methods: {
    goTo(link) {
      window.scrollTo(0, 0);
      this.$router.push(link);
    },

    getInTouchPressed(email) {
      window.open(`mailto:${email}`, '_self');
    }
  }
}
</script>

<style scoped>
header {
  widows: 100%;
  position: fixed;
  z-index: 1000;
}
</style>