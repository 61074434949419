<template>
  <div class="flex gap-x-4 gap-y-16 flex-wrap w-full justify-between max-w-5xl lg:max-w-6xl 2xl:max-w-8xl mx-auto px-4 pb-28 text-blue-900">
    <div class="w-full flex flex-col gap-4 md:w-80">
      <div>
        <img src="@/assets/logos/Homeplan logo (new modern April 2022 - NO .com).svg" style="height: 1.5rem;" alt="Homeplan logo">
      </div>
      <div class="text-center md:text-left font-semibold text-sm text-blue-900">
        "Home Management, Made Simple", "Experience homeownership like a renter" are trademarks of Homeplan.com, Inc
      </div>
      <div class="text-center md:text-left font-semibold text-sm text-blue-900">© 2021-2023 Homeplan.com, Inc</div>
    </div>

    
    <div class="flex flex-col gap-2">
      <div class="font-medium text-lg">Company</div>
      <a @click="goTo('/our-story')" class="cursor-pointer hover:text-blue-800 transition duration-150 ease-out">Our Story</a>
      
      <a @click="goTo('/case-studies')" class="cursor-pointer hover:text-blue-800 transition duration-150 ease-out">Case Studies</a>

      <a @click="goTo('/fair-chance')" class="cursor-pointer hover:text-blue-800 transition duration-150 ease-out">Fair Chance</a>

      <a v-if="$route.path == '/'" @click="scrollTo('faq')" class="cursor-pointer hover:text-blue-800 transition duration-150 ease-out">FAQ</a>
      <router-link v-else to="/#faq" class="cursor-pointer hover:text-blue-800 transition duration-150 ease-out">FAQ</router-link>
    </div>

    <div class="flex flex-col gap-2">
      <div class="font-medium text-lg">Follow</div>
      <a href="https://twitter.com/joinhomeplan" target="_blank" class="cursor-pointer hover:text-blue-800 transition duration-150 ease-out">Twitter</a>
      <a href="https://www.instagram.com/joinhomeplanhq/" target="_blank" class="cursor-pointer hover:text-blue-800 transition duration-150 ease-out">Instagram</a>
      <a href="https://www.linkedin.com/company/homeplaninc" target="_blank" class="cursor-pointer hover:text-blue-800 transition duration-150 ease-out">LinkedIn</a>
      <a @click="goTo('/press')" class="cursor-pointer hover:text-blue-800 transition duration-150 ease-out">Press</a>
    </div>

    <div class="flex flex-col gap-2">
      <div class="font-medium text-lg">Connect</div>
      <a @click="goTo('/servicers')" class="cursor-pointer hover:text-blue-800 transition duration-150 ease-out">Partner With Us</a>
      <a @click="goTo('/jobs')" class="cursor-pointer hover:text-blue-800 transition duration-150 ease-out">Careers - We're Hiring</a>
      <a class="cursor-pointer hover:text-blue-800 transition duration-150 ease-out" href="mailto:hello@joinhomeplan.com">Contact Us</a>
    </div>

    <div class="flex flex-col gap-2">
      <div class="font-medium text-lg">Site</div>
      <a @click="goTo('/privacy')" class="cursor-pointer hover:text-blue-800 transition duration-150 ease-out">Privacy</a>
      <a @click="goTo('/terms')" class="cursor-pointer hover:text-blue-800 transition duration-150 ease-out">Terms</a>
      <a @click="goTo('/data-retention')" class="cursor-pointer hover:text-blue-800 transition duration-150 ease-out">Data Retention</a>
      <a @click="goTo('/cookies')" class="cursor-pointer hover:text-blue-800 transition duration-150 ease-out">Cookies</a>
      <a href="https://homeplan.statuspage.io/" target="_blank" class="cursor-pointer hover:text-blue-800 transition duration-150 ease-out">Status</a>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goTo(link) {
      this.$router.push(link);
      window.scrollTo(0, 0);
    },


    scrollTo(id) {
      let e = document.getElementById(id);
      this.open = false;

      e.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
        inline: 'start'
      });
    }
  }
}
</script>